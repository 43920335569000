.overlay{
  background-color:rgba(0,0,0,0.7);
  position:absolute;
  width:100%;
  height:100%;
  z-index:1500;
}

.modalContainer{
  max-width: 900px;
  width:100%;
  position:relative;
  margin:0 auto;
  margin-top:80px;
}

.closeModal, .right{
float:right;
}

.card-title{
  width:100%;
}

.red, .error_holder{
  color:red;
}

.error_holder{
font-size:13px;
}

.dayBox{
  background-color:black;
  display:inline-block;
  width:20px;
  height:20px;
}

.main_link{
  color:silver;
}

.event_holder{
width:33px;
height:33px;
display:inline-block;
border-radius:50%;
margin-bottom:10px;
border:3px solid white;
text-align: center;
}

.progressive-container{
  width:25%;
  height:50px;
  float:left;
  text-align:center;
  margin-top:5px;
  margin-bottom:20px;
  
}

.loan-container{
  width:30%;
  height:50px;
  float:left;
  text-align:center;
  margin-top:5px;
  margin-bottom:20px;
  
}

.details-container{
  width:25%;
  height:50px;
  float:left;
  text-align:center;
  margin-top:5px;
  margin-bottom:20px;
  
}

.progress-line{
  display:block;
  background-color:white;
  height:2px;
  margin-top: 19px;
}

.label-holder{
  display:inline-block;
  width:120px;
  background-color:rgba(0,0,0,0.1);
  padding-left:7px;
  margin-right:7px;
}

.label-block{
  font-size:14px;
}

.contact-box{
min-height:307px;
}

.kra_image{
width:30px;
}

.shield{
  width:32px;
  }

.block{
display:block;
color:gray;
}

.small-row{
  font-size:13px;
}

.row_black{
background-color:black;
color:white;
}

.small-font{
font-size:13px;
}

.info-holder{
  display:inline-block;
  width:70px;
  font-weight: 600;
}

.info-holder-two{
  display:inline-block;
  width:140px;
}